import { Injectable } from '@angular/core';
import moment from 'moment';

import Parse from 'parse';
import { Observable, of } from 'rxjs';
import {
  type ClassesConfig,
  type ClassAttribute,
  ClassAttributeType,
  type ClassConfig,
  type LocaleConfig,
  GetEntityFormattedValue,
  type ClassRelationObjectSchema,
  type UserObject,
  type ClassTranslatableSchema,
  generateRandomPassword,
  ClassAttributeNumberType,
  ClassFileObjectSchema,
  ClassFilesSchema,
} from '@syspons/monitoring-api-common';
import _ from 'lodash';
import { NgsEntity, NgsId, NgsEntityFormatValuesParams, roundTo } from '@syspons/core-common';
import { NgsCompletable } from '@syspons/ngs-common/models';
import { EntityValueOverride, EntityValueOverrideType } from '@syspons/ngs-entity';

@Injectable({
  providedIn: 'root',
})
export class ParseEntityFactory {
  newInstance(
    obj: Parse.Object,
    classesConfig: ClassesConfig,
    localeConfig: LocaleConfig,
    lang: string,
    input?: any,
  ): ParseEntity {
    const constructEntityAcl = (value: Parse.ACL) => {
      {
        // TODO: ACL model
        // Null = public read + write
        if (value) {
          const acl = [];
          if (value.getPublicReadAccess()) {
            acl.push({
              permission: 'public_read',
              displayname: 'Public Read',
              type: 'acl',
            });
          }
          if (value.getPublicWriteAccess()) {
            acl.push({
              permission: 'public_write',
              displayname: 'Public Write',
              type: 'acl',
            });
          }
          for (const key in value.permissionsById) {
            if (key !== '*') {
              if (value.permissionsById[key].read) {
                acl.push({
                  id: key,
                  permission: 'read',
                  displayname: key + ' Read',
                  type: 'acl',
                });
              }
              if (value.permissionsById[key].write) {
                acl.push({
                  id: key,
                  permission: 'write',
                  displayname: key + ' Write',
                  type: 'acl',
                });
              }
            }
          }
          return acl;
        }
        return value;
      }
    };

    const constructEntityRelation = (objects: ClassRelationObjectSchema[]): ClassRelationObjectSchema[] => {
      return objects.map(o => {
        // Displayname
        const displayname = o.displayname
          ? (o.displayname as ClassTranslatableSchema).type &&
            (o.displayname as ClassTranslatableSchema).type === ClassAttributeType.translatable
            ? ((o.displayname as ClassTranslatableSchema)[lang || 'en'] as string)
            : (o.displayname as string)
          : o.defaultAttribute &&
              (o.defaultAttribute as ClassTranslatableSchema).type === ClassAttributeType.translatable
            ? ((o.defaultAttribute as ClassTranslatableSchema)[lang || 'en'] as string)
            : (o.defaultAttribute as string);
        // Preview
        if (o.preview) {
          Object.keys(o.preview).forEach(key => {
            // Date preview attribute
            if (o.preview[key]?.value instanceof Date) {
              o.preview[key]!.value = moment(o.preview[key]!.value).format(
                classesConfig[o.className]?.attributes[key]?.params.datePickerDateFormat ||
                  localeConfig.defaultDateFormat,
              );
            }
            // Relation as preview attribute
            else if (o.preview[key]?.type === ClassAttributeType.relation) {
              o.preview[key] = {
                key,
                type: ClassAttributeType.relation,
                value: constructEntityRelation(
                  o.preview[key]!.value?.params ? o.preview[key]!.value?.params?.objects : o.preview[key]!.value,
                ),
              };
            }
          });
        }
        return {
          type: 'relation',
          id: o.id,
          objectId: o.id,
          className: o.className,
          displayname: displayname === '' ? o.id : displayname,
          defaultAttribute: o.defaultAttribute,
          preview: o.preview,
        };
      });
    };

    const getValue = (attr: ClassAttribute, input: Parse.Object | any) => {
      let value;
      switch (attr.key) {
        case 'className':
          value = input[attr.key];
          break;

        case 'ACL':
          value = input.getACL ? input.getACL() : null;
          break;

        case 'id':
        case 'objectId':
          value = input.id || input.objectId;
          break;

        default:
          value = input.get ? input.get(attr.key) : input[attr.key];
          break;
      }

      if (value) {
        switch (attr.type) {
          case ClassAttributeType.acl:
            value = constructEntityAcl(value);
            break;

          case ClassAttributeType.date:
            value = moment(value, attr.params.datePickerDateFormat || localeConfig.defaultDateFormat);
            break;

          case ClassAttributeType.relation:
          case ClassAttributeType.pointer:
            if (attr.params) {
              value = constructEntityRelation(
                value.objects
                  ? // Pointer
                    value.objects
                  : // Relation
                    value.params && value.params.objects
                    ? value.params.objects
                    : Array.isArray(value)
                      ? value
                      : [value],
              );
              if (attr.params.relationLimit === 1 && value.length === 1) {
                value = value[0];
              }
            } else {
              // Parse Relation.
              if (Array.isArray(value)) {
                value = value.map(v =>
                  v != null
                    ? {
                        type: 'relation',
                        className: v.className,
                        id: v.id,
                        obj: v.obj,
                      }
                    : v,
                );
              } else {
                value = {
                  type: 'relation',
                  className: value.className,
                  id: value.id,
                  obj: value.obj,
                };
              }
            }
            break;

          case ClassAttributeType.file:
            value = Array.isArray(value)
              ? // Submitted data
                value
              : // Queried data
                value instanceof Parse.File
                ? // S_Files object
                  value
                : // Monitoring Schema
                  value.files;
            break;
        }
      }

      return value;
    };

    const classConfig = classesConfig[obj.className] as ClassConfig;
    const defaultAttribute = obj.get(classConfig.classConfig.defaultAttribute);
    let displayname = input ? input.name : null;

    // Strip a parse object and return clean json format
    // const keys = Object.keys(classesConfig[obj.className].attributes);
    const attrs = {
      id: obj.id,
      className: obj.className,
      obj: obj,
    };
    for (const key in classesConfig[obj.className]?.attributes) {
      const attr = classesConfig[obj.className]!.attributes[key] as ClassAttribute;
      let value = getValue(attr, input || obj);
      if (value instanceof Parse.Object) {
        value = value.id;
      }
      Object.assign(attrs, { [attr.key]: value });
    }

    displayname = defaultAttribute;
    if (defaultAttribute && defaultAttribute.type === ClassAttributeType.translatable) {
      displayname = defaultAttribute[lang || 'en'];
    }
    if (!displayname && classConfig.classConfig.defaultPlaceholder) {
      displayname = classConfig.classConfig.defaultPlaceholder.key;
      if (classConfig.classConfig.defaultPlaceholder.type === 'attribute') {
        displayname =
          classConfig.classConfig.defaultPlaceholder.key === 'objectId'
            ? obj.id
            : obj.get(classConfig.classConfig.defaultPlaceholder.key);
      }
    }

    return this.createEntity(classConfig, localeConfig, lang, attrs, displayname);
  }

  createEntity = (
    classConfig: ClassConfig,
    localeConfig: LocaleConfig,
    lang: string,
    attrs: any,
    displayname: string,
  ): ParseEntity => {
    return new ParseEntity(classConfig, lang, localeConfig.defaultDateFormat).deserialize(attrs, displayname);
  };
}

export class ParseEntity implements NgsEntity, NgsCompletable {
  obj: Parse.Object;

  id: NgsId;
  createdAt: Date;
  updatedAt: Date;
  ACL: Parse.ACL;

  className: string;
  displayname: string;

  constructor(
    public classConfig: ClassConfig,
    public lang: string,
    public defaultDateFormat: string,
  ) {}

  deserialize(input: any, displayname?: string): this {
    return Object.assign(this, input, {
      displayname: (displayname || input.name)?.toString(),
      id: input.id || input.objectId,
    });
  }

  formatValue = (key: string, value: any, params?: NgsEntityFormatValuesParams): any => {
    return GetEntityFormattedValue(
      value,
      this.classConfig && this.classConfig.attributes[key] ? this.classConfig.attributes[key]?.type : undefined,
      this.lang,
      this.classConfig && this.classConfig.attributes[key]
        ? this.classConfig.attributes[key]?.params.datePickerDateFormat || this.defaultDateFormat
        : this.defaultDateFormat,
      params,
    );
  };

  getEntityValue = (key: string, locale?: string): Observable<EntityValueOverride | EntityValueOverride[]> => {
    const getRelationValue = (v: ClassRelationObjectSchema | ClassRelationObjectSchema[]): EntityValueOverride => {
      let res = '';
      if (Array.isArray(v)) {
        const relationObjects: ClassRelationObjectSchema[] = v;
        const names = relationObjects.map(obj => {
          let displayname = obj.defaultAttribute || obj.id;
          if (obj.defaultAttribute && (obj.defaultAttribute as ClassTranslatableSchema).type != null) {
            displayname = (
              getValue(displayname, (obj.defaultAttribute as ClassTranslatableSchema).type) as EntityValueOverride
            ).value;
          }
          if (!displayname || displayname === '') {
            displayname = obj.id;
          }
          return displayname;
        }) as string[];
        res = names[0] as string;
        names.forEach((a: any, i: number) => {
          if (i > 0) {
            res += '<br/>' + a;
          }
        });
      } else if (v.displayname) {
        res = (getValue(v.displayname, (v.displayname as ClassTranslatableSchema).type) as EntityValueOverride).value;
      }
      return { type: EntityValueOverrideType.string, value: res };
    };
    const gerTranslatableValue = (v: ClassTranslatableSchema): EntityValueOverride => ({
      type: EntityValueOverrideType.string,
      value: v[locale || this.lang || 'en'],
    });
    const getDateValue = (v: Date): EntityValueOverride => {
      let res = '';
      if (attr && attr.params.datePickerDateFormat) {
        res = moment(v, attr.params.datePickerDateFormat).format(attr.params.datePickerDateFormat);
      } else if (this.defaultDateFormat) {
        res = moment(v, this.defaultDateFormat).format(this.defaultDateFormat);
      }
      if (res === 'Invalid date') {
        res = v.toISOString();
      }
      return { type: EntityValueOverrideType.string, value: res };
    };
    const getNumberValue = (v: number): EntityValueOverride => {
      let res: number | string;
      if (attr && attr.params.numberType === ClassAttributeNumberType.integer) {
        res = parseInt(v + '');
      } else {
        res = roundTo(v, (attr && attr.params.floatDecimals) || 1) + '';
        res = res.replace('.', ',');
      }
      return {
        type: typeof res === 'number' ? EntityValueOverrideType.number : EntityValueOverrideType.string,
        value: res,
      };
    };
    const getFileValue = (
      v: ClassFilesSchema | ClassFileObjectSchema | ClassFileObjectSchema[],
    ): EntityValueOverride | EntityValueOverride[] => {
      const constructValue = (_v: ClassFileObjectSchema): EntityValueOverride => {
        if (_v.file?._url) {
          return { type: EntityValueOverrideType.url, value: { url: _v.file._url, displayname: _v.displayname } };
        } else {
          return { type: EntityValueOverrideType.string, value: _v.displayname };
        }
      };
      if ((v as ClassFilesSchema).files) {
        v = (v as ClassFilesSchema).files as ClassFileObjectSchema[];
      }
      return Array.isArray(v) ? v.map(file => constructValue(file)) : constructValue(v as ClassFileObjectSchema);
    };
    const getValue = (v: any, type?: string): EntityValueOverride | EntityValueOverride[] => {
      if (v != null) {
        if (type) {
          switch (type) {
            case ClassAttributeType.translatable:
              return gerTranslatableValue(v);

            case ClassAttributeType.relation:
            case ClassAttributeType.pointer:
              return getRelationValue(v);

            case ClassAttributeType.date:
              return getDateValue(v);

            case ClassAttributeType.number:
              return getNumberValue(v);

            case ClassAttributeType.file:
              return getFileValue(v);

            default:
              return { type: typeof v as EntityValueOverrideType, value: v };
          }
        } else {
          return { type: typeof v as EntityValueOverrideType, value: v };
        }
      } else {
        return { type: EntityValueOverrideType.string, value: v };
      }
    };

    const value: any = this[key as keyof ParseEntity];
    let res: EntityValueOverride | EntityValueOverride[] | undefined = undefined;
    const attr = this.classConfig.attributes[key] as ClassAttribute;

    if (attr) {
      res = getValue(value, attr.type);
    }
    // relationPreview
    else if (!attr && key.indexOf('.') > 0) {
      // Search in relations
      const relKey = key.substring(0, key.indexOf('.'));
      const previewKey = key.substring(key.indexOf('.') + 1, key.length);
      let previewValues: EntityValueOverride[] = [];
      let previewRes;
      let type: ClassAttributeType | undefined;
      let relation = (this[relKey as keyof ParseEntity] || []) as
        | ClassRelationObjectSchema
        | ClassRelationObjectSchema[];
      if (!Array.isArray(relation)) {
        relation = [relation];
      }
      relation.map(rel => {
        if (rel.preview[previewKey] != null) {
          type = rel.preview[previewKey]!.type;
          switch (type) {
            case ClassAttributeType.relation:
            case ClassAttributeType.translatable:
              previewValues.push(getValue(rel.preview[previewKey]!.value, type) as EntityValueOverride);
              break;

            case ClassAttributeType.file:
              previewRes = getValue(rel.preview[previewKey]!.value, type) as EntityValueOverride[];
              break;

            default:
              previewValues.push(getValue(rel.preview[previewKey]!.value, undefined) as EntityValueOverride);
              break;
          }
        }
      });

      if (!previewRes) {
        previewValues = previewValues.filter(p => p.value != null);
        let firstValue = previewValues[0]?.value != null ? previewValues[0].value : previewValues[0];
        previewValues.forEach((a, i) => {
          if (i > 0) {
            firstValue += '<br/>' + (a.value ? a.value : a);
          }
        });
        previewRes = { type: EntityValueOverrideType.html, value: firstValue };
      }

      res = previewRes;
    }

    return res != undefined ? of(res) : of({ type: EntityValueOverrideType.string, value: '' });
  };

  post = (): Promise<{ key?: string; result: any }> => {
    return new Promise((resolve, reject) => {
      let result: any;
      if (this.className === '_User') {
        const userObj = this.obj as Parse.User<UserObject>;
        if (!userObj.get('username')) {
          result = userObj.set(
            'username',
            `${this.obj.get('first_name')}.${userObj.get('last_name')}<$>${new Date().getTime()}</$>`,
          );
        }
        if (!userObj.get('password')) {
          const password = generateRandomPassword(this.classConfig.additionalConfig?.params.passwordPolicy);
          userObj.set('temp_password', password);
          result = userObj.set('password', password);
        }
      }
      resolve({ result });
    });
  };
}

export declare type ParseEntities = ParseEntity[];
