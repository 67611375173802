import { inject, Injectable } from '@angular/core';
import { UserEntity, UserFactory } from './user.model';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import type { KeycloakProfile } from 'keycloak-js';
import Parse from 'parse';
import { ActivatedRouteSnapshot, RouterStateSnapshot, type CanActivateFn } from '@angular/router';
import { NgsSnackbarService } from '@syspons/ngs-snackbar';
import { NgsOptionsService } from '@syspons/ngs-storage';
import { LOCALE_OPTION_KEY } from '@syspons/ngs-locale';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  type CloudFunctionResult,
  MonitoringCloudFunctions,
  MonitoringSchemaConfig,
  type RoleObject,
  type UserObject,
} from '@syspons/monitoring-api-common';

import { ParseCloudController } from '../parseCloud/parseCloud.Controller';
import ParseSystemService from '../parseSystem/parseSystem.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CurrentUserController {
  user: Parse.User<UserObject>;
  userRoles$: BehaviorSubject<Parse.Role<RoleObject>[] | null> = new BehaviorSubject<Parse.Role<RoleObject>[] | null>(
    null,
  );
  currentUserEntity$: BehaviorSubject<UserEntity | null> = new BehaviorSubject<UserEntity | null>(null);
  userLiveQuery: Parse.LiveQuerySubscription;
  lang: string;

  constructor(
    private systemService: ParseSystemService,
    private parseCloudController: ParseCloudController,
    private ngsOptionsService: NgsOptionsService,
    private keycloakService: KeycloakService,
    private userFactory: UserFactory,
    private snackBar: NgsSnackbarService,
  ) {
    this.systemService.error$.pipe(untilDestroyed(this)).subscribe((error: any) => {
      if (error && error.code) {
        switch (error.code) {
          case 209:
            // Invalid session token
            this.logOut();
            break;
        }
      }
    });
  }

  // Final user load
  setCurrentUser = () => {
    const setUser = (user: Parse.User) => {
      console.log('CurrentUser: Set user ' + user.id);
      this.user = user as Parse.User<UserObject>;
      this.currentUserEntity$.next(
        this.userFactory.newInstance(
          user,
          this.systemService.systemObj.get('classes_config'),
          this.systemService.systemObj.get('local_config'),
          this.lang,
        ) as UserEntity,
      );
      this.queryUserRoles();
    };

    const currentUser = Parse.User.current() as Parse.User;
    if (currentUser) {
      if (!this.userLiveQuery) {
        new Parse.Query<Parse.User<UserObject>>(MonitoringSchemaConfig.user.className)
          .equalTo('objectId', currentUser.id)
          .subscribe()
          .then(subscription => {
            this.userLiveQuery = subscription;
            subscription.on('update', user => {
              if (this.systemService.systemObj) {
                setUser(user as Parse.User);
              }
            });
          }, this.onError);
      }
      combineLatest([
        this.systemService.systemObjFetched$,
        this.ngsOptionsService.getObservable<string>(LOCALE_OPTION_KEY),
      ])
        .pipe(untilDestroyed(this))
        .subscribe(([systemObjFetched, lang]) => {
          this.lang = lang;
          if (systemObjFetched) {
            setUser(Parse.User.current() as Parse.User);
            this.saveLocale();
          }
        });
    }
  };

  logIn(): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('On keycloak login');
      this.keycloakService.isLoggedIn().then((res: any) => {
        if (res) {
          this.keycloakService.getToken().then(token => {
            this.keycloakService.loadUserProfile().then(userProfile => {
              resolve({
                isLoggedIn: res,
                token: token,
                user: userProfile,
              });
            }, reject);
          }, reject);
        } else {
          this.logOut().then(res => {
            reject(new Error('Keycloak login failed'));
          }, reject);
        }
      }, reject);
    });
  }

  logOut(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (Parse.serverURL && Parse.User.current()) {
          Parse.User.logOut().then(
            () => {
              this.keycloakService.logout().then(resolve, reject);
            },
            e => {
              console.log(e);
              this.keycloakService.logout().then(resolve, reject);
            },
          );
        } else {
          this.keycloakService.logout().then(resolve, reject);
        }
      } catch (e) {
        console.error(e);
        this.keycloakService.logout().then(resolve, reject);
      }
    });
  }

  linkParseUser(): Promise<Parse.User<UserObject>> {
    return new Promise((resolve, reject) => {
      const loginWithKeyCloak = (keycloakUser: KeycloakProfile) => {
        console.log('On loginWithKeyCloak');
        this.keycloakService.getToken().then(accessToken => {
          Parse.User.logInWith<Parse.User<UserObject>>('keycloak', {
            authData: { access_token: accessToken, id: keycloakUser.id },
          }).then(
            user => {
              if (!user.get('email')) {
                user.set('email', keycloakUser.email as string);
              }
              user.set('keycloak_id', keycloakUser.id as string);
              user.set('first_name', keycloakUser.firstName as string);
              user.set('last_name', keycloakUser.lastName as string);
              user.save().then(
                res => {
                  this.setCurrentUser();
                  resolve(res);
                },
                e => {
                  this.onError(e, reject);
                },
              );
            },
            e => {
              this.logOut();
              this.onError(e, reject);
            },
          );
        });
      };

      const becomeExistingUser = (keycloakUser: KeycloakProfile) => {
        console.log('On Become Existing User');
        this.parseCloudController
          .runCloudFunction<{ keycloakUser: KeycloakProfile }, any>(MonitoringCloudFunctions.BecomeExistingUser, {
            keycloakUser,
          })
          .then(
            (result: CloudFunctionResult<string>) => {
              Parse.User.become(result.result).then(
                res => {
                  this.setCurrentUser();
                  resolve(res as Parse.User<UserObject>);
                },
                e => {
                  this.onError(e, reject);
                },
              );
            },
            e => {
              // Cannot run cloud function for non logged in user
              console.error(e);
              loginWithKeyCloak(keycloakUser);
            },
          );
      };

      console.log('on Link Parse User');
      Parse.User.currentAsync().then(
        user => {
          if (user) {
            console.log('Current cached user loaded:', user);
            // Cached user found
            user.fetch().then(
              _user => {
                console.log('Current cached refreshed:', _user);
                this.setCurrentUser();
                resolve(_user as Parse.User<UserObject>);
              },
              e => this.onError(e, reject),
            );
          } else {
            console.log('No cached user found');
            // Cached user not found
            this.keycloakService.loadUserProfile().then(
              (keycloakUser: KeycloakProfile) => {
                becomeExistingUser(keycloakUser);
              },
              e => this.onError(e, reject),
            );
          }
        },
        (e: Error) => {
          console.log('On currentAsync error: ' + e.message || e);
          // Cached user not found
          console.error(e);
          this.keycloakService.loadUserProfile().then(
            (keycloakUser: KeycloakProfile) => {
              loginWithKeyCloak(keycloakUser);
            },
            e => this.onError(e, reject),
          );
        },
      );
    });
  }

  queryUserRoles() {
    const getRoles = () => {
      new Parse.Query('_Role')
        .containedIn(
          'objectId',
          this.currentUserEntity$.value?.roles.params.objects?.map(role => role['id']) as string[],
        )
        .find()
        .then((roles: any) => {
          if (roles) {
            this.userRoles$.next(roles);
          }
        }, this.onError);
    };
    if (this.currentUserEntity$.value) {
      if (
        this.currentUserEntity$.value.roles &&
        this.currentUserEntity$.value.roles.params.objects &&
        this.currentUserEntity$.value.roles.params.objects.length > 0
      ) {
        getRoles();
      } else {
        // Set default User role
        const query = new Parse.Query(Parse.Role);
        if (
          this.systemService.systemObj?.get('classes_config')['_User']?.additionalConfig?.params?.defaultRole?.value
        ) {
          query.equalTo(
            'objectId',
            this.systemService.systemObj?.get('classes_config')['_User']?.additionalConfig?.params?.defaultRole?.value,
          );
        } else {
          query.equalTo('name', MonitoringSchemaConfig.permissions.userRoleName);
        }
        query.first().then(userRole => {
          if (userRole) {
            this.currentUserEntity$.value?.roles.params.objects?.push({
              id: userRole.id,
              className: userRole.className,
              defaultAttribute: 'name',
              displayname: userRole.get('name'),
              preview: { name: userRole.get('name') },
            });
            this.currentUserEntity$.value?.obj.set('roles', this.currentUserEntity$.value.roles);
            this.currentUserEntity$.value?.obj.save().then(user => {
              this.setCurrentUser();
            }, this.onError);
          } else {
            // User role was not found
          }
        }, this.onError);
      }
    }
  }

  saveLocale = () => {
    if (this.lang) {
      let locale = this.user.get('locale');
      if (!locale) {
        locale = { lang: '', timezone: '' };
      }
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (locale.lang !== this.lang || locale.timezone !== timezone) {
        locale.lang = this.lang;
        locale.timezone = timezone;
        this.user.set('locale', locale);
        this.user.save();
      }
    }
  };

  hasRoutePermission = (route: string | string[]): boolean => {
    if (route) {
      if (this.currentUserEntity$.value && this.userRoles$.value) {
        const routes = Array.isArray(route) ? route : [route];
        const uiPermissions = this.userRoles$.value.map(role => role.get('ui_permissions'));
        let found = routes.find(path => path === 'debug') != null;
        // let found = true;
        if (!found && uiPermissions) {
          uiPermissions.forEach(
            permissions =>
              permissions &&
              permissions.params &&
              permissions.params.objects.forEach(o => {
                if (
                  routes.find(path =>
                    o.preview['route'] && o.preview['route'].value
                      ? o.preview['route'].value.toLowerCase() === path.toLowerCase()
                      : o.defaultAttribute &&
                        typeof o.defaultAttribute === 'string' &&
                        o.defaultAttribute.toLowerCase().replaceAll(' ', '_') ===
                          path.toLowerCase().replaceAll(' ', '_'),
                  )
                ) {
                  found = true;
                  return;
                }
              }),
          );
        }
        return found;
      } else {
        return false;
      }
    }
    return false;
  };

  canActivate = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    if (route.url.length > 0) {
      return this.hasRoutePermission(route.url.map(url => url.path));
    }
    return true;
  };

  //

  onError = (e: Error | string, reject?: (e: Error | string) => void) => {
    this.snackBar.open(e instanceof Error ? e.message : e);

    if (
      e instanceof Error &&
      (e.message === 'Invalid session token' ||
        e.message === 'User was not found' ||
        e.message === 'Account already exists for this username.' ||
        e.message === 'Object not found.')
    ) {
      this.logOut();
    }
    if (reject) {
      reject(e);
    }
  };
}

export const canActivateRoute: CanActivateFn = (route, state) => {
  return new Promise((resolve, reject) => {
    resolve(inject(CurrentUserController).canActivate(route, state));
  });
};
