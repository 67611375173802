import { NgModule } from '@angular/core';
import { TableItemSizeDirective } from './table-item-size.directive';


@NgModule({
  declarations: [TableItemSizeDirective],
  imports: [],
  exports: [TableItemSizeDirective]
})
export class TableVirtualScrollModule {
}
