import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { StoreConfig } from '@syspons/monitoring-api-common';

/**
 * A service to share the Parse config
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export default class StoreConfigService {
  private _config: StoreConfig;

  set config(config: StoreConfig) {
    this._config = config;
  }

  get config() {
    return this._config;
  }
}
